export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route:'/'
    // children: [
    //   {
    //     title: 'Ecommerce',
    //     route: '/',
    //   },
    // ],
  },
  
  {
    title: 'States',
    route: 'states',
    icon: 'Navigation2Icon',
  },
  {
    title: 'Specializations',
    route: 'specializations',
    icon: 'MenuIcon',
  },  
  {
    title: 'Doctors',
    route: 'doctors',
    icon: 'UserPlusIcon',
  },  
  {
    title: 'Companies',
    route: 'companies',
    icon: 'GridIcon',
  },
  {
    title: 'Countries',
    route: 'countries',
    icon: 'MapPinIcon',
  },
  {
    title: 'Hospitals',
    route: 'hospitals',
    icon: 'FolderPlusIcon',
  },
  {
    title: 'Regions',
    route: 'regions',
    icon: 'SunsetIcon',
  },
  {
    title: 'MedicalReps',
    route: 'medicalReps',
    icon: 'GitPullRequestIcon',
  },
  {
    title: 'Visits',
    route: 'visits',
    icon: 'ListIcon',
  },
  {
    title: 'Pharmacies',
    route: 'pharmacies',
    icon: 'PocketIcon',
  },
  {
    title: 'Pharmacy Report',
    route: 'pharmaciesReport',
    icon: 'PocketIcon'
  },
  {
    title: 'Pharmaceuticals',
    route: 'pharmaceuticals',
    icon: 'PackageIcon',
  },
  

  {
    title: 'Admin',
    icon: 'UserIcon',
    children: [
      {
        title: 'Admins',
        route: 'admins'
      },
      {
        title: 'Permissions',
        route: 'permissions'
      }
    ]
  },

  
  
  // {
  //   title: 'Machines',
  //   icon: 'TruckIcon',
  //   children: [
  //       {
  //         title: 'Machine Type',
  //         route: 'machineType',
  //       },
  //       {
  //           title: 'Machine',
  //           route: 'machine',
  //       },
  //   ],
  // },
  // {
  //   title: 'Products',
  //   icon: 'PackageIcon',
  //   children: [
  //       {
  //         title: 'Products',
  //         route: 'products',
  //       },
  //       {
  //         title: 'Services',
  //         route: 'services',
  //       },
  //       {
  //           title: 'Categories',
  //           route: 'categories',
  //       },
  //       // {
  //       //     title: 'Companies',
  //       //     route: 'companies',
  //       // },
  //       {
  //         title: 'Machine',
  //         route: 'machine',
  //       },
  //       {
  //         title: 'Machine Size',
  //         route: 'machineSize',
  //       },
  //       {
  //           title: 'Brands',
  //           route: 'brands',
  //       },
  //       // {
  //       //     title: 'Units',
  //       //     route: 'unit',
  //       // },
  //   ],
  // },
  // {
  //   title: 'Additional Services',
  //   route: 'additionalServices',
  //   icon: 'SlackIcon',
  // },
  // {
  //   title: 'Orders',
  //   route: 'orders',
  //   icon: 'ShoppingCartIcon',
  // },
  // {
  //   title: 'Users',
  //   icon: 'UsersIcon',
  //   children: [{
  //           title: 'Customers',
  //           route: 'customers',
  //       },
  //       // {
  //       //     title: 'Workshop',
  //       //     route: 'workShop',
  //       // },

  //   ],
  // },
  // {
  //   title: 'Work shop',
  //   icon: 'TruckIcon',
  //   children: [
  //       {
  //           title: 'Captains',
  //           route: 'captains',
  //       },
  //       {
  //           title: 'Workshop',
  //           route: 'workShop',
  //       },
  //       {
  //         title: 'Region',
  //         route: 'regions',
  //       },
  //       {
  //         title: 'Components',
  //         route: 'component',
  //       },

  //   ],
  // },


  // {
  //   title: 'Inventories',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'workShop Income',
  //       route: 'inventoriesWorkshop',
  //     },
  //     {
  //       title: ' region',
  //       route: 'inventoriesRegion',
  //     },
  //   ]
  // },
  
  
  // {
  //   title: 'Coupon',
  //   route: 'coupon',
  //   icon: 'PercentIcon',
  // },
  // {
  //   title: 'Contact',
  //   route: 'contacts',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Choose Factor',
  //   route:'chooseFactor',
  //   icon: 'MapPinIcon'
  // },

  
  
]
