<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      :class="{ hideItem: checkPermissionData(item) }"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    checkPermissionData(routeName) {
      if (routeName.route == "/") {
        return false
      }

      var permission = $cookies.get(process.env.VUE_APP_NAME+'_Privilege')
      if (permission.length > 0) {
        if (routeName.route) {
        if (permission.includes(routeName.route)) {
          return false
        }else{
          return true
        }
      }else {
        Object.entries(routeName.children).forEach(([key, value]) => {
          // console.log(value.route);
          if (permission.includes(value.route)) {
            return false
          }else{
            return true
          }
            
        });
      }
      }
      
      
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>

<style>
.hideItem {
  
  background-color: red;
}
</style>
